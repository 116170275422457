import { withMembersArea, MA_APP_IDS } from '@wix/members-area-integration-kit';

const photoSharingAppDefId = '476dc2dd-e16e-43b0-a0aa-1a486c78fbe2';

let appToken;
let sdk;

const getSharedGalleryEditorApi = () => ({
  async editorReady(_editorSDK, _appToken, options) {
    appToken = _appToken;
    sdk = _editorSDK;
    const isEditorX = options.origin.initiator === "EDITOR" && options.origin.type === "RESPONSIVE";
    if (options.firstInstall && isEditorX) {
      sdk.addEventListener('widgetAdded', async (event) => {
        const { detail } = event;
        const { componentRef } = detail;
        const responsiveLayout = await sdk.document.responsiveLayout.get(
          'token',
          { componentRef },
        );
        await sdk.responsiveLayout.update('token', {
          componentRef,
          responsiveLayout: {
            ...responsiveLayout,
            componentLayouts: [
              {
                ...responsiveLayout.componentLayouts[0],
                width: { type: 'percentage', value: 100 },
              },
            ],
            itemLayouts: [
              {
                ...responsiveLayout.itemLayouts[0],
                justifySelf: 'center',
              },
            ],
          },
        });
      });
    }
  },
  handleAction(args) {
    const type = args.type,
      payload = args.payload;
    try {
      switch (type) {
        case 'appInstalled':
          switch (payload.appDefinitionId) {
            case photoSharingAppDefId: {
              return sdk.pages.data
                .getAll(appToken)
                .then(allPages => {
                  const filesPage = allPages.find(
                    page =>
                      page.tpaPageId === 'Shared Gallery' &&
                      page.tpaApplicationId === photoSharingAppDefId,
                  );
                  return (
                    filesPage &&
                    sdk.document.pages.navigateTo(appToken, {
                      pageLink: { type: 'PageLink', pageId: filesPage.id },
                    })
                  );
                })
                .then(() => sdk.document.save());
            }
            default:
              return Promise.resolve();
          }
        default:
          return Promise.resolve();
      }
    } catch (e) {
      Promise.reject();
      throw e;
    }
  },
});

const editorApp = withMembersArea(getSharedGalleryEditorApi(), {
  membersAreaApps: [
    MA_APP_IDS.ALL_MEMBERS,
    MA_APP_IDS.NOTIFICATIONS,
    MA_APP_IDS.SHARED_GALLERY,
  ],
});

export const editorReady = editorApp.editorReady;
export const handleAction = editorApp.handleAction;
